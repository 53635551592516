import { Box, Image, Menu, ResponsiveContext, Tab, Tabs, Text } from "grommet";
import React from "react";

import { navigate } from "gatsby-link";
import { UserManager } from "grommet-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../components/elements/layout";
import Subscriptions from "../components/my_trustiway/souscriptions";
import mainLogo from "../images/logo.png";
import { getSouscriptionConfig, logout } from "../services/login_service";
import storageService, { saveDomainAgence } from "../services/storage_service";
import BoitOutils from "../components/my_trustiway/boit_outil";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      menuOpen: false,
      updateNum: 1,
      user: {},
      partenaireData: {},
    };
  }
  componentDidMount() {
    // navigate('/login'); return;
    const token = storageService.getToken();
    if (!token) {
      navigate("/login");
      return;
    }
    this.setState({ token });
    const user = storageService.getUser();
    this.setState({ user });
    // console.log("user = ", user);
    if (user.id) {
      this.getConfigs(user.id);
    }
    if(!user.prenom){
      navigate("/profile");
        return;
    }
  }

  getConfigs = async (uid) => {
    const res = await getSouscriptionConfig(uid);
    const partenaireDataArr = res.data;
    if (partenaireDataArr.length > 0) {
      const partenaireData = partenaireDataArr[0];
      console.log("partenaire = ", partenaireData);
      if (partenaireData.need_change) {
        navigate("/first_time");
        return;
      }
      saveDomainAgence(partenaireData?.domain_email || partenaireData?.domainName);
      this.setState({
        partenaireData,
        updateNum: this.state.updateNum + 1,
      });
    }
  };

  showMessage = (message, messageType) => {
    messageType = messageType || "info";
    toast[messageType](message);
  };

  showMenu = () => {
    this.setState({ menuOpen: true });
  };

  logout = () => {
    logout();
    navigate("/login");
  };

  changepw = () => {
    navigate("/change");
  };

  render() {
    console.log("this.state.partenaireData = ", this.state.partenaireData);
    var _domain = this.state.partenaireData?.domain_email || this.state.partenaireData?.domainName || this?.state?.user?.email?.split('@').pop();
    if (_domain?.length <= 0) {
      _domain = this?.state?.user?.email?.split('@').pop();
    }
    return (
      <Layout style={{ justifyContent: "center", alignContent: "center" }}>
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box
              as="main"
              flex
              overflow="auto"
              style={{ width: "100%", backgroundColor: "#fff" }}
              direction="column"
              alignContent="center"
              alignSelf="center"
            >
              <Box
                justify="center"
                style={{ position: "absolute", left: 20, top: 13 }}
              >
                <Image src={mainLogo} width={200} />
              </Box>
              <Box
                direction="row"
                alignContent="between"
                pad="medium"
                style={{
                  height: 80,
                  width: "100%",
                  backgroundColor: "#F0F0F0",
                }}
              >
                <Box justify="center" flex>
                  <Tabs margin="-6px 0 0 0">

                    <Tab title="Mise en relation">
                      <Subscriptions agence={this.state.partenaireData} showMessage={() => {}} />
                    </Tab>

                    <Tab title="Boite à outils">
                      <BoitOutils agence={this.state.partenaireData} showMessage={() => {}} />
                    </Tab>
                    
                  </Tabs>
                </Box>
              </Box>

              <Box
                direction="row"
                gap="small"
                justify="center"
                align="center"
                alignContent="center"
                style={{ position: "absolute", right: 5, top: 13 }}
              >
                <Box>
                  <Text size="small" textAlign="end">
                    Bonjour <b>{this?.state?.user?.nom} {this?.state?.user?.prenom}</b>
                  </Text>

                </Box>
                <Menu
                  icon={this.state.partenaireData.logo ? <Image src={this.state.partenaireData.logo} style={{ height: 40 }}></Image> : <UserManager />}
                  items={[
                    {
                      label: "Changer votre mot de passe",
                      onClick: this.changepw,
                    },
                    { label: "Se déconnecter", onClick: this.logout },
                  ]}
                />
              </Box>
            </Box>
          )}
        </ResponsiveContext.Consumer>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Layout>
    );
  }
}

export default Home;
