const axios = require('axios');
const storageService = require('../../../../services/storage_service');
const { baseAPI } = require('../../../../config/config.json');
const HEADERS = {
    'Authorization': `Bearer ${storageService.getToken()}`
};

const buildFilter = (params, search_params) => {
    const { agence, query, filter } = search_params;
    let localFilter = {...filter};
    Object.keys(localFilter || {}).forEach(key => {
        if (!localFilter[key]) delete localFilter[key];
        if(localFilter[key] === 'Inconnu') {
            delete localFilter[key];
            localFilter[`_where[0][owner_null]`] = 'true';
        }
    })
    if (localFilter) {
        params = { ...params, ...localFilter };
    }
    if (agence) {
        params['owner_id'] = agence.id;
    }
    if (query) {
        params['_q'] = query;
    }
    console.log(params);
    return params;
}

const getSubscriptions = (search_params) => {
    const { start, sort } = search_params;
    let limit = search_params.limit || 50;
    let params = {
        _start: start,
        _limit: limit,
    }
    if(sort) {
        params._sort = sort;
    }
    params = buildFilter(params, search_params);
    return axios.get(`${baseAPI}/v1/commercials/subscriptions`, { params, headers: HEADERS })
}

const selectAll = (search_params) => {
    const { start, sort } = search_params;
    let limit = search_params.limit || 50;
    let params = {
        _start: start,
        _limit: limit,
    }
    if(sort) {
        params._sort = sort;
    }
    params = buildFilter(params, search_params);
    return axios.get(`${baseAPI}/v1/commercials/subscriptions/select-all`, { params, headers: HEADERS })
}

const exportSubs = (search_params) => {
    let body = search_params;
    return axios.post(`${baseAPI}/v1/commercials/subscriptions/export`, body, { headers: HEADERS })
}

const countSubscriptions = (search_params) => {
    let params = {}
    params = buildFilter(params, search_params);
    return axios.get(`${baseAPI}/v1/commercials/subscriptions/count`, { params, headers: HEADERS })
}

const getSubsReport = (search_params) => {
    let params = {}
    params = buildFilter(params, search_params);
    return axios.get(`${baseAPI}/v1/commercials/subscriptions/report`, { params, headers: HEADERS })
}

const updateSubscription = (subscription) => {
    return axios.put(`${baseAPI}/v1/commercials/subscriptions/${subscription.id}`, subscription, { headers: HEADERS });
}

const patchSubscription = (field, value, ids) => {
    return axios.patch(`${baseAPI}/v1/commercials/subscriptions/patch`, { field, value, ids }, { headers: HEADERS });
}

const billingSubscriptions = (ids) => {
    return axios.put(`${baseAPI}/v1/commercials/subscriptions/billing`, { ids }, { headers: HEADERS });
}

const uploadMassSubscription = (body) => {
    return axios.post(`${baseAPI}/v1/commercials/subscriptions/batch-files`, body, { headers: HEADERS });
}

const updateSubPaidStatus = (ids, paied = true, deleted = false) => {
    return axios.put(`${baseAPI}/v1/commercials/subscriptions/paied`, { ids, paied, deleted }, { headers: HEADERS });
}

const bulkDelete = (ids) => {
    return axios.post(`${baseAPI}/v1/commercials/subscriptions/bulk-delete`, { ids }, { headers: HEADERS });
}

const getFacturation = (agence) => {
    return axios.get(`${baseAPI}/v1/commercials/subscriptions/facturation`, { params: { agence_id: agence.id }, headers: HEADERS });
}

const sendAttestation = (subId, mailType) => {
    return axios.post(`${baseAPI}/attestations/${mailType}`, { id: subId }, { headers: HEADERS });
}

const createClientIfNotExist = (sub_id, contact) => {
    return axios.post(`${baseAPI}/v1/commercials/subscriptions/client`, {sub_id, contact}, { headers: HEADERS });
}

module.exports = {
    getSubscriptions,
    selectAll,
    countSubscriptions,
    updateSubscription,
    uploadMassSubscription,
    updateSubPaidStatus,
    patchSubscription,
    billingSubscriptions,
    bulkDelete,
    exportSubs,
    sendAttestation,
    getFacturation,
    createClientIfNotExist,
    getSubsReport
}