import { Accordion, AccordionPanel, Box, Image, Text } from "grommet";
import React, { useState } from "react";
import bookingsync from '../../../images/booking-sync.png';
import { ventCanals } from "../souscriptions/utils/types";

const BoitOutils = ({ agence }) => {

    const [activeIndex, setActiveIndex] = useState([0]);


    return (
        <Box
            width={'full'}
            pad={"large"}
            justify="center"
            align="center"
        >

            <Box width={'large'} justify='center' align="center" gap="small">
                <Accordion multiple fill activeIndex={activeIndex} gap="small" onActive={(newActiveIndex) => setActiveIndex(newActiveIndex)}>
                    {agence.booking_sync_account &&
                        <AccordionPanel
                            header={renderPanelHeader('Vente via Booking Sync', activeIndex.includes(1))}
                        >
                            <Box pad={'medium'}>
                                <Box direction="row" gap="small" align="center">
                                    <Image src={bookingsync} />
                                </Box>
                                <Box pad={'small'} gap='xsmall'>
                                    <Text size="small">{`ID BookingSync : ${agence.booking_sync_account?.account_id}`}</Text>
                                    <Text size="small">{`Client : ${agence.booking_sync_account?.active ? 'Active' : 'Desactive'}`}</Text>
                                    <Text size="small">{`Client : ${agence.booking_sync_account?.option_trustiway}`}</Text>
                                </Box>
                            </Box>
                        </AccordionPanel>
                    }
                    {agence.voyage_products.filter(p => p.type === 'Lien').length > 0 &&
                        <AccordionPanel
                            header={renderPanelHeader('Vente via les liens', activeIndex.includes(2))}
                        >
                            <Box pad={'medium'}>
                                <Text size="small"><b>Lien produits :</b> </Text>
                                {
                                    agence.voyage_products.filter(p => p.type === 'Lien').map(p => (
                                        <Text size="xsmall">{p.link}</Text>
                                    ))
                                }
                                <br />
                                <Text size="small"><b>Lien classique :</b> </Text>
                                {
                                    agence.voyage_products.filter(p => p.type === 'Lien').map(p => (
                                        <Text size="xsmall">{p.link_agence}</Text>
                                    ))
                                }

                                {agence.ventCanals.includes(ventCanals[1]) &&
                                    <>
                                        <br />
                                        <Text size="small"><b>Lien personnalisé :</b> </Text>
                                        {
                                            agence.voyage_products.filter(p => p.link_agence_personnalise).map(p => (
                                                <Box style={{ paddingTop: 5 }}>
                                                    <Text size="xsmall">
                                                        <b>{p.product} :</b> <br /><span>{p.link_agence_personnalise}</span>
                                                        {p.link_agence_personnalise_en && <span>
                                                            <br />
                                                            <u>English: <br /></u>{p.link_agence_personnalise_en}
                                                        </span>}
                                                    </Text>
                                                </Box>
                                            ))
                                        }
                                    </>
                                }
                            </Box>
                        </AccordionPanel>
                    }
                    {agence.voyage_products.filter(p => p.type === "Formulaire en direct").length > 0 && agence.formulaire_direct &&
                        <AccordionPanel
                            header={renderPanelHeader('Vente via Formulaire en direct', activeIndex.includes(3))}
                        >
                            <Text size="small"><b>Formulaire en direct: </b></Text>
                            {<Box pad={'medium'}>
                                <Text size="xsmall"><b>Compte client :</b></Text>
                                <Text size="xsmall">Identifiant : <b> {agence.formulaire_direct.email}</b></Text>
                                <Text size="xsmall">Mot de passe initial : <b> {agence.formulaire_direct?.init_password}</b></Text>
                            </Box>
                            }
                        </AccordionPanel>
                    }
                    {agence.voyage_products.filter(p => p.type === "API").length > 0 &&
                        <AccordionPanel
                            header={renderPanelHeader('Vente via API', activeIndex.includes(8))}
                        >
                            <Box pad={'medium'}>
                                <Text size="small"><b>Lien d'API: </b></Text>
                                <Text size="small"><i>PreProd (clé: 6d14e4e049478800da6277cf38040090)</i></Text>
                                {agence.voyage_products.filter(p => p.type === 'API' && p.link).map(p => (
                                    <Text size="xsmall">{p.link.replace('api.trustiway', 'api.dev.trustiway')}</Text>
                                ))
                                }
                                <Box pad={'xsmall'} />
                                <Text size="small"><i>Prod (clé: a1f6e4e9b6cd4b382d5d0c3422022d56)</i></Text>
                                {agence.voyage_products.filter(p => p.type === 'API' && p.link).map(p => (
                                    <Text size="xsmall">{p.link}</Text>
                                ))
                                }
                                <Text size="xsmall">
                                    <a href="https://storage.googleapis.com/trustiway-bo-dev.appspot.com/API_doc_13_03_22_40e0b07096/API_doc_13_03_22_40e0b07096.docx"><b>Doc API</b></a>
                                </Text>
                                <Box pad={'xsmall'} />
                                <Text size="small"><b>Lien d'API Proposition: </b></Text>
                                <Text size="xsmall">
                                    <a href="https://storage.googleapis.com/trustiway-bo-dev.appspot.com/API_Proposision_20210924_5385e4de2d/API_Proposision_20210924_5385e4de2d.pdf"><b>Doc API Proposition</b></a>
                                </Text>
                            </Box>
                        </AccordionPanel>
                    }
                </Accordion>
            </Box>
        </Box>

    )
}
export const renderPanelHeader = (title, active) => (
    <Box fill direction="row" justify="between" pad="xsmall" background={'#CECECE'} style={{ paddingLeft: 20, paddingRight: 20, borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
        <strong>
            <Text>{title}</Text>
        </strong>
        <Text>{active ? 'Fermez ce volet  ►' : 'Ouvriez ce volet  ▼'}</Text>
    </Box>
);

export default BoitOutils;