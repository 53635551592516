import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import React from "react";

function DateInput({ value, onChange, disabled, size, style, ...rest }) {
  return (
    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils} style={{...style}} >
      <KeyboardDatePicker
        autoOk
        disabled={disabled}
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        value={value || null}
        size={size}
        InputAdornmentProps={{ position: "end" }}
        onChange={onChange}
        style={{...style}}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}

function DateTimeInput({ value, onChange, disabled, size, style }) {
  return (
    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        autoOk
        disabled={disabled}
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy HH:mm"
        value={value || null}
        size={size}
        InputAdornmentProps={{ position: "end" }}
        onChange={onChange}
        InputProps={{readOnly: false}}
        ampm={false}
        style={{...style}}
      />
    </MuiPickersUtilsProvider>
  );
}


function TimeInput({ value, onChange, disabled, size }) {
  return (
    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
      <KeyboardTimePicker
        autoOk
        disabled={disabled}
        variant="inline"
        inputVariant="outlined"
        format="HH:mm"
        value={value || null}
        size={size}
        InputAdornmentProps={{ position: "end" }}
        onChange={onChange}
        InputProps={{readOnly: false}}
        ampm={false}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DateInput;

export {
  DateTimeInput,
  TimeInput
};
