import { Button } from 'grommet';
import React from 'react';

const buttonStyle = { paddingTop: 5, paddingBottom: 5, paddingLeft: 20, paddingRight: 20, border: 'none' };
const PrimaryButton = ({ icon, onClick, label, background, color }) => {

    let style = background ? Object.assign({ background }, buttonStyle) : buttonStyle;
    style = color ? Object.assign({ color }, style) : style;

    return <Button
        icon={icon} 
        primary 
        style={style} 
        onClick={onClick} 
        label={label}
    />
}

export default PrimaryButton;