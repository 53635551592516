import { Box, Button, Heading, Layer, Text } from 'grommet';
import { Add, Close, Search, SubtractCircle } from 'grommet-icons';
import moment from 'moment';
import React, { useState } from 'react';
import CustomInput from '../../../elements/custom_input';
import DateInput from '../../../elements/date_input';
import DateInputString from '../../../elements/date_input_string';
import CustomSelect from '../../../elements/select_fill';
import ToggleButton from '../../../elements/toggle_button/toggle_button';
import products from '../utils/types';
import MultipleFilters from './multiple_filter';

const PROPS = {
    commercial_id: 'commercial_id',
    date_de_souscription: 'date_de_souscription',
    agence: 'agence',
    produit: 'produit',
    mode_de_payment: 'mode_de_payment',
    date_creation: 'createdAt',
    status_payment: 'status_payment',
    ref_import: 'ref_import',
}

const initList = filter => {
    let list = [];
    console.log(filter);
    Object.keys(filter).forEach(prop => {
        if(prop.includes('_in]')) return;
        let fil = FILTER_PROPS.find(p => prop.includes(p.prop) || (prop.includes('owner') && p.prop === 'agence'));
        let existing = list.find(l => prop.includes(l.prop));
        if (existing) {
            if (prop.includes('_where[1]')) {
                existing.end = filter[prop];
            } else {
                existing.value = filter[prop];
            }
        } else {
            list.push({
                prop: fil.prop,
                label: fil.label,
                value: prop.includes('_where[1]') ? '' : filter[prop],
                end: prop.includes('_where[1]') ? filter[prop] : '',
                operator: prop.includes('_ne') ? "n'est pas" : prop.includes('_where') ? 'entre' : 'est'
            })
        }
    })
    return list;
}

const FILTER_PROPS = [
    { label: 'Date de souscription', prop: 'date_de_souscription' },
    { label: 'Date de création', prop: 'createdAt' },
    { label: 'Produit', prop: 'produit' },
    { label: 'Moyen de paiement', prop: 'mode_de_payment' },
    { label: 'Etat', prop: 'status_payment' },
    { label: 'Ref import', prop: 'ref_import' },
]

const VoyageFilter = ({
    commerciaux,
    agences,
    onFilterApply,
    filter,
    onClose,
}) => {

    const [list, setList] = useState(initList(filter));

    const [isMultiple, setIsMultiple] = useState(false);

    const onLocalFilterChange = (prop, value, localFilter) => {
        localFilter[prop] = value;
        setList(list.map(l => l));
    }

    const onRemoveFilter = (filter) => {
        let newList = list.filter(l => l.prop !== filter.prop);
        setList(newList.map(l => l));
    }

    return (
        <Layer>

            <Box justify='center' align='center' style={{ width: 700 }} pad='small' gap='xsmall' overflow={'auto'}>
                <Close style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }} onClick={onClose} />
                <Heading level={5} textAlign='center'>Filtre votre recherche</Heading>
                <Box justify='center' align='center'>
                    <ToggleButton size={'small'} groups={[false, true].map(val => (
                        {
                            label: val ? 'Filtre multiple' : 'Filtre simple',
                            selected: val === isMultiple,
                            onClick: () => setIsMultiple(val),
                          }
                    ))}/>
                </Box>
                {isMultiple &&
                    <MultipleFilters onClose={onClose} onFilterApply={onFilterApply} filter={filter} />
                }
                {!isMultiple &&
                    <Box justify='center' align='center' style={{ width: 700 }} pad='small' gap='xsmall' overflow={'auto'}>

                        <Box gap='small'>
                            {
                                list.map((localFilter, index) => buildFilter(localFilter, commerciaux, agences, onLocalFilterChange, onRemoveFilter, list))
                            }
                        </Box>

                        <Box direction='row' gap='medium'>
                            <Button primary
                                icon={<Add />}
                                onClick={() => {
                                    let newFilter = {}
                                    list.push(newFilter);
                                    setList(list.map(l => l));
                                }}
                            />
                            {list.filter(p => p.value)
                                .filter(p => p.prop && p.value).length > 0 &&
                                <Button primary
                                    icon={<Search />}
                                    style={{ background: '#59A4F4' }}
                                    onClick={() => {
                                        let result = {};
                                        list.forEach(l => {
                                            if (l.prop === 'createdAt') {
                                                result['_where[0][' + l.prop + '_gte]'] = moment(l.value).startOf('date').toDate();
                                                result['_where[1][' + l.prop + '_lte]'] = moment(l.value).endOf('date').toDate();
                                            }
                                            else if (l.operator === 'entre') {
                                                result['_where[0][' + l.prop + '_gte]'] = l.value;
                                                result['_where[1][' + l.prop + '_lte]'] = l.end;

                                            } else if (l.operator === "n'est pas") {
                                                if (l.value === 'Inconnu') {
                                                    result['_where[3][owner_null]'] = "false";
                                                } else {
                                                    result['_where[3][owner.raisonSocial_ne]'] = l.value;
                                                }
                                            }
                                            else {
                                                result[l.prop] = l.value
                                            }
                                        });
                                        onFilterApply(result);
                                        onClose();
                                    }}
                                />

                            }
                        </Box>
                    </Box>
                }
            </Box>
        </Layer>
    )
}

const buildFilter = (filter, commerciaux, agences, onLocalFilterChange, onRemoveFilter, existingFilters) => {

    if (!filter.prop) {
        return (
            <SearchLineGeneric
                filter={filter}
                onPropSelected={onLocalFilterChange}
                onRemoveFilter={onRemoveFilter}
                existingFilters={existingFilters}
            />
        )
    } else {
        return getControl(filter, commerciaux, agences, onLocalFilterChange, onRemoveFilter);
    }
}

const SearchLineGeneric = ({ filter, onPropSelected, onRemoveFilter, existingFilters }) => {
    return (
        <Box direction='row' align='center' width='large'>
            <Box style={{ width: '40%' }}>
                <CustomSelect
                    size={'xsmall'}
                    options={FILTER_PROPS.filter(p => !existingFilters.map(s => s.prop).includes(p.prop)).map(o => o.label)}
                    value={filter.label}
                    onChange={({ option }) => {
                        let prop = FILTER_PROPS.find(p => p.label === option);
                        onPropSelected('prop', prop.prop, filter);
                        onPropSelected('label', prop.label, filter);
                    }}
                />
            </Box>
            <Box style={{ width: '50%' }} >

            </Box>
            <Box style={{ width: '5%' }} >
                <SubtractCircle color='brand' style={{ cursor: 'pointer' }} onClick={onRemoveFilter} />
            </Box>
        </Box>
    )
}

const getControl = (filter, commerciaux, agences, onLocalFilterChange, onRemoveFilter) => {
    const onFilterChange = (value) => onLocalFilterChange('value', value, filter);
    switch (filter.prop) {
        case PROPS.commercial_id:
            return (
                <SearchLineOption
                    key={Math.random().toString().substring(2, 10)}
                    text={'Propriétaire de la transaction'}
                    options={commerciaux.map(c => c.name)}
                    value={filter.value ? commerciaux.find(c => c.id === filter.value)?.name : undefined}
                    onChange={({ option }) => {
                        let commercial_id = commerciaux.find(s => s.name === option).id;
                        onFilterChange(commercial_id);
                    }}
                    onRemoveFilter={() => {
                        onRemoveFilter(filter);
                    }}
                />
            );
        case PROPS.agence:
            return (
                <SearchSelect
                    key={Math.random().toString().substring(2, 10)}
                    text={'Entreprise associé'}
                    filter={filter}
                    onChange={({ option }) => onFilterChange(option)}
                    onRemoveFilter={() => onRemoveFilter(filter)}
                    onFilterChange={(prop, value) => {
                        console.log('prop: ', prop, value);
                        onLocalFilterChange(prop, value, filter);
                        console.log('ok filter: ', filter);
                    }}
                    operatorOptions={['est', "n'est pas"]}
                    agences={agences}
                />
            );
        case PROPS.date_de_souscription:
            return (
                <SearchLineDateString
                    key={Math.random().toString().substring(2, 10)}
                    text={'Date de souscription'}
                    filter={filter}
                    onChange={(value) => onFilterChange(value)}
                    onFilterChange={(prop, value) => onLocalFilterChange(prop, value, filter)}
                    onRemoveFilter={() => onRemoveFilter(filter)}
                />
            );
        case PROPS.date_creation:
            return (
                <SearchLineDate
                    key={Math.random().toString().substring(2, 10)}
                    text={'Date de création'}
                    filter={filter}
                    onChange={(value) => onFilterChange(value)}
                    onFilterChange={(prop, value) => onLocalFilterChange(prop, value, filter)}
                    onRemoveFilter={() => onRemoveFilter(filter)}
                />
            );
        case PROPS.mode_de_payment:
            return (
                <SearchLineOption
                    key={Math.random().toString().substring(2, 10)}
                    text={'Mode de paiement'}
                    options={[
                        "Souscription en direct",
                        "API",
                        "Chèque",
                        "Carte de crédit"
                    ]}
                    value={filter.value}
                    onChange={({ option }) => onFilterChange(option)}
                    onRemoveFilter={() => onRemoveFilter(filter)}
                />
            );
        case PROPS.status_payment:
            return (
                <SearchLineOption
                    key={Math.random().toString().substring(2, 10)}
                    text={'Etat'}
                    options={[
                        "Proposition envoyé",
                        "En attente de paiement",
                        "En facturation",
                        "Payé",
                        "Remboursement prime",
                    ]}
                    value={filter.value}
                    onChange={({ option }) => onFilterChange(option)}
                    onRemoveFilter={() => onRemoveFilter(filter)}
                />
            );
        case PROPS.produit:
            return (
                <SearchLineOption
                    key={Math.random().toString().substring(2, 10)}
                    text={'Produit'}
                    options={products}
                    value={filter.value}
                    onChange={({ option }) => onFilterChange(option)}
                    onRemoveFilter={() => onRemoveFilter(filter)}
                />
            )
        case PROPS.ref_import:
            return (
                <SearchLineInput
                    key={Math.random().toString().substring(2, 10)}
                    text={'Ref import'}
                    value={filter.value}
                    onChange={value => onFilterChange(value)}
                    onRemoveFilter={() => onRemoveFilter(filter)}
                />
            )
    }
}

const SearchLineOption = ({ text, value, options, filter, onChange, onRemoveFilter, operatorOptions, onFilterChange }) => {
    return (
        <Box direction='row' align='center' width='large' gap='small'>
            <Box style={{ width: '25%' }}>
                <Text size='xsmall'>{text}</Text>
            </Box>
            {!operatorOptions &&
                <Box style={{ width: '70%' }} >
                    <CustomSelect
                        size={'xsmall'}
                        options={options}
                        value={value}
                        onChange={onChange}
                    />
                </Box>
            }
            {operatorOptions &&
                <Box style={{ width: '70%' }} direction='row' gap='medium' align='center'>
                    {filter.operator && <Text size='xsmall'><b>{filter.operator}</b></Text>}
                    {!filter.operator &&
                        <CustomSelect
                            size={'xsmall'}
                            options={operatorOptions}
                            value={filter.operator}
                            onChange={({ option }) => {
                                onFilterChange('operator', option);
                            }}
                        />
                    }
                    {filter.operator &&
                        <CustomSelect
                            size={'xsmall'}
                            options={options}
                            value={filter.value}
                            onChange={onChange}
                        />
                    }
                </Box>
            }
            <Box style={{ width: '5%' }} >
                <SubtractCircle color='brand' style={{ cursor: 'pointer' }} onClick={onRemoveFilter} />
            </Box>
        </Box>
    )
}

const SearchLineDateString = ({ text, filter, onChange, onRemoveFilter, onFilterChange }) => {
    const [operator, setOperator] = useState(filter.operator);

    return (
        <Box direction='row' align='center' width='large' gap='small'>
            <Box style={{ width: '25%' }}>
                <Text size='xsmall'>{text}</Text>
            </Box>
            <Box style={{ width: '70%' }} direction='row' gap='medium' align='center'>

                {!operator &&
                    <CustomSelect
                        size={'xsmall'}
                        options={['est', 'entre']}
                        placeholder='est ou entre'
                        value={operator}
                        onChange={({ option }) => {
                            setOperator(option);
                        }}
                    />
                }
                {operator && <Text size='xsmall'><b>{operator}</b></Text>}
                {operator &&
                    <DateInputString
                        size={'small'}
                        strValueInFRFormat={filter.value}
                        onChange={value => {
                            onFilterChange('operator', operator);
                            onChange(value);
                        }}
                    />
                }
                {operator === 'entre' &&
                    <DateInputString
                        size={'small'}
                        strValueInFRFormat={filter.end}
                        onChange={value => {
                            console.log(value);
                            onFilterChange('operator', operator);
                            onFilterChange('end', value);
                        }}
                    />
                }

            </Box>
            <Box style={{ width: '5%' }} >
                <SubtractCircle color='brand' style={{ cursor: 'pointer' }} onClick={onRemoveFilter} />
            </Box>
        </Box>
    )
}

const SearchLineDate = ({ text, value, onChange, onRemoveFilter, filter }) => {
    return (
        <Box direction='row' align='center' width='large' gap='small'>
            <Box style={{ width: '40%' }}>
                <Text size='xsmall'>{text}</Text>
            </Box>
            <Box style={{ width: '50%' }}>
                <DateInput
                    size={'small'}
                    value={filter.value}
                    onChange={onChange}
                />
            </Box>
            <Box style={{ width: '10%' }} >
                <SubtractCircle color='brand' style={{ cursor: 'pointer' }} onClick={onRemoveFilter} />
            </Box>
        </Box>
    )
}

const SearchSelect = ({ text, agences, filter, onChange, onRemoveFilter, operatorOptions, onFilterChange }) => {
    const initAgences = () => {
        if (filter.value) {
            agences.push(filter.value);
        }
        return agences;
    }
    const [options, setOptions] = useState(initAgences());
    const [value, setValue] = useState(filter.value);
    console.log(filter, console.log(options.map(a => a.raisonSocial)));

    return (
        <Box direction='row' align='center' width='large' gap='small'>
            <Box style={{ width: '25%' }}>
                <Text size='xsmall'>{text}</Text>
            </Box>
            <Box style={{ width: '70%' }} direction='row' gap='medium' align='center'>
                {filter.operator && <Text size='xsmall'><b>{filter.operator}</b></Text>}
                {!filter.operator &&
                    <CustomSelect
                        size={'xsmall'}
                        options={operatorOptions}
                        value={filter.operator}
                        onChange={({ option }) => {
                            onFilterChange('operator', option);
                        }}
                    />
                }
            </Box>
            <Box style={{ width: '5%' }} >
                <SubtractCircle color='brand' style={{ cursor: 'pointer' }} onClick={onRemoveFilter} />
            </Box>
        </Box>
    )
}

const SearchLineInput = ({ text, value, onChange, onRemoveFilter }) => {
    const [_value, setValue] = useState(value);

    return (
        <Box direction='row' align='center' width='large' gap='small'>
            <Box style={{ width: '40%' }}>
                <Text size='xsmall'>{text}</Text>
            </Box>
            <Box style={{ width: '50%' }}>
                <CustomInput
                    size='xsmall'
                    value={_value}
                    onChange={event => setValue(event.target.value)}
                    onBlur={event => onChange(event.target.value)}
                />
            </Box>
            <Box style={{ width: '10%' }} >
                <SubtractCircle color='brand' style={{ cursor: 'pointer' }} onClick={onRemoveFilter} />
            </Box>
        </Box>
    )
}

export default VoyageFilter;